import { FooterLinkType } from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'

export const footerLinks: (t: ContextApi['t']) => FooterLinkType[] = (t) => [
  {
    label: t('About'),
    items: [
      {
        label: t('Contact'),
        href: "https://docs.metaworth.exchange/contact-us",
      },
      {
        label: t('Partner website'),
        href: 'http://www.metaworth.exchange/',
      },
      {
        label: t('Blog'),
        href: "https://medium.com/@metaworth",
      },
      {
        label: t('Community'),
        href: "https://t.me/metaworth_exchange",
      },
      {
        label: t('Buy MTW Token'),
        href: '/presale',
      },
      {
        label: '—',
      },
      {
        label: t('Online Store'),
        href: 'http://www.metaworth.store/',
        isHighlighted: true,
      },
    ],
  },
  {
    label: t('Help'),
    items: [
      {
        label: t('Customer Support'),
        href: '/MetaworthExchangeCustomerSupport.pdf',
      },
      {
        label: t('Troubleshooting'),
        href: 'https://docs.metaworth.exchange',
      },
      {
        label: t('Guides'),
        href: '/MetaworthDoc.pdf',
      },
    ],
  },
  {
    label: t('Developers'),
    items: [
      {
        label: 'Github',
        href: "https://github.com/metaworth/metaworth/blob/main/mtw.sol",
      },
      {
        label: t('Documentation'),
        href: '/MetaworthDoc.pdf',
      },
      {
        label: t('Bug Bounty'),
        href: '/MetaworthExchangeBugbounty.pdf',
      },
      {
        label: t('Careers'),
        href: 'mailto:hiring@metaworth.exchange',
      },
    ],
  },
]
