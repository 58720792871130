import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { Currency, Trade, TradeType } from '@pancakeswap/sdk'
import { Button, Text, AutoRenewIcon } from '@pancakeswap/uikit'
import { useTranslation } from 'contexts/Localization'
import { AutoColumn } from 'components/Layout/Column'
import { AutoRow, RowBetween, RowFixed } from 'components/Layout/Row'
import { useBNBBusdPrice } from 'hooks/useBUSDPrice'
import { StyledBalanceMaxMini, SwapCallbackError } from './styleds'

const SwapModalFooterContainer = styled(AutoColumn)`
  margin-top: 24px;
  padding: 16px;
  border-radius: ${({ theme }) => theme.radii.default};
  border: 1px solid ${({ theme }) => theme.colors.cardBorder};
  background-color: ${({ theme }) => theme.colors.background};
`

export default function BuyModalFooter({
  tokenPrice,
  invertTokenPrice,
  inputCurrency,
  outputCurrency,
  onConfirm,
  buyErrorMessage,
}: {
  tokenPrice: number
  invertTokenPrice: number
  inputCurrency: Currency
  outputCurrency: Currency
  onConfirm: () => void
  buyErrorMessage: string | undefined
}) {
  const { t } = useTranslation()
  const [showInverted, setShowInverted] = useState<boolean>(false)

  const _bnbBusdPrice = useBNBBusdPrice()?.toSignificant(6)
  const bnbBusdPrice = (+_bnbBusdPrice)*10**6
  const formattedPrice = showInverted ? (inputCurrency?.symbol === 'BNB'? (tokenPrice/bnbBusdPrice) : (tokenPrice/10**6)) : (inputCurrency?.symbol === 'BNB'? (invertTokenPrice*bnbBusdPrice/10**12) : (invertTokenPrice/10**6))
  
  const show = Boolean(inputCurrency && outputCurrency)
  const label = showInverted
    ? `${inputCurrency?.symbol}/${outputCurrency?.symbol}`
    : `${outputCurrency?.symbol}/${inputCurrency?.symbol}`

  return (
    <>
      <SwapModalFooterContainer>
        <RowBetween align="center">
          <Text fontSize="14px">{t('Price')}</Text>
          <Text
            fontSize="14px"
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              textAlign: 'right',
              paddingLeft: '10px',
            }}
          >
            {formattedPrice?.toFixed(3) ?? '-'} {label}
            <StyledBalanceMaxMini onClick={() => setShowInverted(!showInverted)}>
              <AutoRenewIcon width="14px" />
            </StyledBalanceMaxMini>
          </Text>
        </RowBetween>
      </SwapModalFooterContainer>

      <AutoRow>
        <Button
          variant='primary'
          onClick={onConfirm}
          mt="12px"
          id="confirm-swap-or-send"
          width="100%"
        >
          Confirm Buy
        </Button>

        {buyErrorMessage ? <SwapCallbackError error={buyErrorMessage} /> : null}
      </AutoRow>
    </>
  )
}
