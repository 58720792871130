import { useCallback } from 'react'
import { ethers, Contract } from 'ethers'
import { useBUSDContract, usePresaleContract } from 'hooks/useContract'
import { useCallWithGasPrice } from 'hooks/useCallWithGasPrice'

const useApproveBUSD = () => {
  const PresaleContract = usePresaleContract()
  const BUSDContract = useBUSDContract()
  const { callWithGasPrice } = useCallWithGasPrice()
  const handleApprove = useCallback(async () => {
    const tx = await callWithGasPrice(BUSDContract, 'approve', [PresaleContract.address, ethers.constants.MaxUint256])
    const receipt = await tx.wait()
    return receipt.status
  }, [BUSDContract, PresaleContract, callWithGasPrice])

  return { onApproveBUSD: handleApprove }
}

export default useApproveBUSD
