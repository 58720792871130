import { MenuItemsType, DropdownMenuItemType, menuStatus } from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'
import { nftsBaseUrl } from 'views/Nft/market/constants'

export type ConfigMenuItemsType = MenuItemsType & { hideSubNav?: boolean }

const config: (t: ContextApi['t']) => ConfigMenuItemsType[] = (t) => [
  {
    label: t('Presale'),
    icon: 'Swap',
    href: '/presale',
    items: [],
  },
//   {
//     label: t('Trade'),
//     icon: 'Swap',
//     href: '/swap',
//     items: [
//       {
//         label: t('Exchange'),
//         href: '/swap',
//       },
//       {
//         label: t('Liquidity'),
//         href: '/liquidity',
//       },
//     ],
//   },
//   {
//     label: t('Earn'),
//     href: '/farms',
//     icon: 'Earn',
//     items: [
//       {
//         label: t('Farms'),
//         href: '/farms',
//       },
//       {
//         label: t('Pools'),
//         href: '/pools',
//       },
//     ],
//   },
//   {
//     label: t('Win'),
//     href: '/lottery',
//     icon: 'Trophy',
//     items: [
//       {
//         label: t('Prediction (BETA)'),
//         href: '/prediction',
//         status: menuStatus.SOON,
//       },
//       {
//         label: t('Lottery'),
//         href: '/lottery',
//         status: menuStatus.SOON,
//       },
//     ],
//  },
//  {
//     label: t('Launch Services'),
//     icon: 'Community',
//     href: '/listingconsultancy',
//     items: [
//       {
//         label: t('Listing Consultancy'),
//         href: '/listingconsultancy',
//         status: menuStatus.SOON,
//       },
//       {
//         label: t('KYC & Audit'),
//         href: 'https://forms.gle/1ejTh8zbefA6qi6NA',
//         type: DropdownMenuItemType.EXTERNAL_LINK,
//       },
//       {
//         label: t('IDO'),
//         href: '/ido',
//         status: menuStatus.SOON,
//       },
//       {
//         label: t('IFO'),
//         href: '/ifo',
//         status: menuStatus.SOON,
//       },
//       {
//         label: t('NFT Launchpad'),
//         href: '/nftlaunchpad',
//         status: menuStatus.SOON,
//       },
//     ],
//   },
//   {
//     label: t('NFT'),
//     href: `${nftsBaseUrl}`,
//     icon: 'Nft',
//     items: [
//       {
//         label: t('Overview'),
//         href: `${nftsBaseUrl}`,
//         status: menuStatus.SOON,
//       },
//       {
//         label: t('Collections'),
//         href: `${nftsBaseUrl}/collections`,
//         status: menuStatus.SOON,
//       },
//       {
//         label: t('Activity'),
//         href: `${nftsBaseUrl}/activity`,
//         status: menuStatus.SOON,
//       },
//     ],
//   },
//   {
//     label: 'More',
//     href: '/info',
//     icon: 'More',
//     hideSubNav: true,
//     items: [
//       {
//         label: t('Analytics'),
//         href: '/info',
//       },
//       {
//         label: t('IFO'),
//         href: '/ifo',
//       },
//       {
//         label: t('Voting'),
//         href: 'https://snapshot.org/#/metaworth-exchange.eth',
//         type: DropdownMenuItemType.EXTERNAL_LINK,
//       },
//       {
//         type: DropdownMenuItemType.DIVIDER,
//       },
//       {
//         label: t('Leaderboard'),
//         href: '/teams',
//       },
//       {
//         type: DropdownMenuItemType.DIVIDER,
//       },
//       {
//         label: t('Blog'),
//         href: 'https://medium.com/@metaworth',
//         type: DropdownMenuItemType.EXTERNAL_LINK,
//       },
//       {
//         label: t('Docs'),
//         href: '/MetaworthDoc.pdf',
//         type: DropdownMenuItemType.EXTERNAL_LINK,
//       },
//     ],
//   },
]

export default config
