import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { CurrencyAmount, JSBI } from '@pancakeswap/sdk'
import {
  Button,
  Heading,
  Text,
  ArrowDownIcon,
  Box,
  useModal,
  Flex,
  IconButton,
  useMatchBreakpoints,
  Link,
} from '@pancakeswap/uikit'
import { useTranslation } from 'contexts/Localization'
import BigNumber from 'bignumber.js'
import { parseUnits } from 'ethers/lib/utils'
import { useBNBBusdPrice } from 'hooks/useBUSDPrice'
import useRefresh from 'hooks/useRefresh'
import { ethersToBigNumber } from 'utils/bigNumber'
import { useBUSDContract, usePresaleContract, useUSDTContract } from 'hooks/useContract'
import { GreyCard } from 'components/Card'
import useToast from 'hooks/useToast'
import { useCallWithGasPrice } from 'hooks/useCallWithGasPrice'
import { getBscScanLink } from 'utils'
import { AppState } from 'state'
import CircleLoader from 'components/Loader/CircleLoader'
import useApproveUSDT from './hooks/useApproveUSDT'
import useApproveBUSD from './hooks/useApproveBUSD'
import Column, { AutoColumn } from '../../components/Layout/Column'
import ConfirmBuyModal from './components/ConfirmBuyModal'
import CurrencyInputPanelForPresale from '../../components/CurrencyInputPanelForPresale'
import { AutoRow, RowBetween } from '../../components/Layout/Row'
import { Wrapper } from './components/styleds'
import { AppBody } from '../../components/App'
import ConnectWalletButton from '../../components/ConnectWalletButton'

import useActiveWeb3React from '../../hooks/useActiveWeb3React'
import useWrapCallback, { WrapType } from '../../hooks/useWrapCallback'
import { Field } from '../../state/swap/actions'
import {
  tryParseAmount,
  useDerivedSwapInfo,
  useSwapActionHandlers,
  useSwapState,
} from '../../state/swap/hooks'
import {
  useExchangeChartManager,
} from '../../state/user/hooks'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import Page from '../Page'
import { StyledInputCurrencyWrapper, StyledSwapContainer } from './styles'
import CurrencyInputHeader from './components/CurrencyInputHeader'
import TradePrice from './components/TradePrice'


const Label = styled(Text)`
  font-size: 12px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.secondary};
`

const StyledText = styled(Text)`
  margin-top: 50px;
  width: 350px;
  text-align: center;
`

export default function Presale() {
  const { t } = useTranslation()
  const { isMobile } = useMatchBreakpoints()

  const { account, library, chainId } = useActiveWeb3React()

  const state = useSelector<AppState, AppState['transactions']>((s) => s.transactions)
  const transactions = useMemo(() => (chainId ? state[chainId] ?? {} : {}), [chainId, state])

  // swap state
  const { independentField, typedValue } = useSwapState()
  const { currencyBalances, parsedAmount, currencies, inputError: swapInputError } = useDerivedSwapInfo()

  // Price data
  const {
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId },
  } = useSwapState()

  const { toastSuccess, toastError } = useToast()
  const { callWithGasPrice } = useCallWithGasPrice()

  const PresaleContract = usePresaleContract()
  const USDTContract = useUSDTContract()
  const BUSDContract = useBUSDContract()

  const { onApproveUSDT } = useApproveUSDT()
  const { onApproveBUSD } = useApproveBUSD()

  const [requestedApproval, setRequestedApproval] = useState(false)
  const [requestedBuy, setRequestedBuy] = useState(false)
  const [USDTisApproved, setUSDTApprove] = useState(false)
  const [BUSDisApproved, setBUSDApprove] = useState(false)
  const [inputAmountOfToken, setInputAmountOfToken] = useState('0')
  const [outputAmountOfToken, setOutputAmountOfToken] = useState('0')
  const [isPresaleOpen, setIsPresaleOpen] = useState(true)
  const [tokenPrice, setTokenPrice] = useState(30000000)
  const [invertTokenPrice, setInvertTokenPrice] = useState(1 / tokenPrice * 10 ** 12)

  // modal and loading
  const [{ buyErrorMessage, attemptingTxn, txHash }, setBuyState] = useState<{
    attemptingTxn: boolean
    buyErrorMessage: string | undefined
    txHash: string | undefined
  }>({
    attemptingTxn: false,
    buyErrorMessage: undefined,
    txHash: undefined,
  })

  const { fastRefresh } = useRefresh()

  const handleUSDTApprove = useCallback(async () => {
    try {
      setRequestedApproval(true)
      await onApproveUSDT()
    } catch (e) {
      toastError(t('Error'), t('Please try again. Confirm the transaction and make sure you are paying enough gas!'))
      console.error(e)
    } finally {
      setRequestedApproval(false)
    }
  }, [onApproveUSDT, t, toastError])

  const handleBUSDApprove = useCallback(async () => {
    try {
      setRequestedApproval(true)
      await onApproveBUSD()
    } catch (e) {
      toastError(t('Error'), t('Please try again. Confirm the transaction and make sure you are paying enough gas!'))
      console.error(e)
    } finally {
      setRequestedApproval(false)
    }
  }, [onApproveBUSD, t, toastError])

  const _bnbBusdPrice = useBNBBusdPrice()?.toSignificant(6)
  const bnbBusdPrice = (+_bnbBusdPrice)*10**6
  const initialParseAmount = tryParseAmount('0', currencies[Field.INPUT])
  const [calculatedInputAmount, setCalculatedInputAmount] = useState(initialParseAmount)
  const [calculatedOutputAmount, setCalculatedOutputAmount] = useState(initialParseAmount)

  const parsedAmounts = {
        [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : calculatedInputAmount,
        [Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : calculatedOutputAmount,
      }

  const { onCurrencySelection, onUserInput } = useSwapActionHandlers()
  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value)
      setInputAmountOfToken(value)
      if (value && currencies[Field.INPUT] && currencies[Field.OUTPUT]) {
        if (currencies[Field.INPUT].symbol === 'BNB') {
          const outputValue = (+value)*bnbBusdPrice/tokenPrice
          setOutputAmountOfToken(outputValue.toString())
          const parsedOutputValue = parseUnits(outputValue.toString(), 18)
          const _parsedOutputAmount = CurrencyAmount.ether(JSBI.BigInt(parsedOutputValue))
          setCalculatedOutputAmount(_parsedOutputAmount)
        }
        else {
          const outputValue = (+value)*10**6/tokenPrice
          setOutputAmountOfToken(outputValue.toString())
          const parsedOutputValue = parseUnits(outputValue.toString(), 18)
          const _parsedOutputAmount = CurrencyAmount.ether(JSBI.BigInt(parsedOutputValue))
          setCalculatedOutputAmount(_parsedOutputAmount)
        }
      }
      else {
        setCalculatedOutputAmount(tryParseAmount('0', currencies[Field.OUTPUT]))
        setOutputAmountOfToken('0')
      }
    },
    [onUserInput, currencies, tokenPrice, bnbBusdPrice],
  )
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value)
      setOutputAmountOfToken(value)
      if (value && currencies[Field.INPUT] && currencies[Field.OUTPUT]) {
        if (currencies[Field.INPUT].symbol === 'BNB') {
          const inputValue = (+value)*10**12/(invertTokenPrice * bnbBusdPrice)
          setInputAmountOfToken(inputValue.toString())
          const parsedInputValue = parseUnits(inputValue.toString(), 18)
          const _parsedInputAmount = CurrencyAmount.ether(JSBI.BigInt(parsedInputValue))
          setCalculatedInputAmount(_parsedInputAmount)
        }
        else {
          const inputValue = (+value)*10**6/invertTokenPrice
          setInputAmountOfToken(inputValue.toString())
          const parsedInputValue = parseUnits(inputValue.toString(), 18)
          const _parsedInputAmount = CurrencyAmount.ether(JSBI.BigInt(parsedInputValue))
          setCalculatedInputAmount(_parsedInputAmount)
        }
      }
      else {
        setInputAmountOfToken('0')
        setCalculatedInputAmount(tryParseAmount('0', currencies[Field.INPUT]))
      }
    },
    [onUserInput, currencies, bnbBusdPrice, invertTokenPrice],
  )

  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: parsedAmounts[dependentField]?.toSignificant(6) ?? '',
  }

  const maxAmountInput: CurrencyAmount | undefined = maxAmountSpend(currencyBalances[Field.INPUT])
  const atMaxAmountInput = Boolean(maxAmountInput && parsedAmounts[Field.INPUT]?.equalTo(maxAmountInput))

  const [showInverted, setShowInverted] = useState<boolean>(!false)

  const handleInputSelect = useCallback(
    (inputCurrency) => {
      onCurrencySelection(Field.INPUT, inputCurrency)
      handleTypeInput('0')
    },
    [onCurrencySelection, handleTypeInput],
  )

  const handleMaxInput = useCallback(() => {
    if (maxAmountInput) {
      onUserInput(Field.INPUT, maxAmountInput.toExact())
      setInputAmountOfToken(maxAmountInput.toExact())
      if (maxAmountInput.toExact() && currencies[Field.INPUT] && currencies[Field.OUTPUT]) {
        if (currencies[Field.INPUT].symbol === 'BNB') {
          const outputValue = (+(maxAmountInput.toExact()))*bnbBusdPrice/tokenPrice
          setOutputAmountOfToken(outputValue.toString())
          const parsedOutputValue = parseUnits(outputValue.toString(), 18)
          const _parsedOutputAmount = CurrencyAmount.ether(JSBI.BigInt(parsedOutputValue))
          setCalculatedOutputAmount(_parsedOutputAmount)
        }
        else {
          const outputValue = (+(maxAmountInput.toExact()))*10**6/tokenPrice
          setOutputAmountOfToken(outputValue.toString())
          const parsedOutputValue = parseUnits(outputValue.toString(), 18)
          const _parsedOutputAmount = CurrencyAmount.ether(JSBI.BigInt(parsedOutputValue))
          setCalculatedOutputAmount(_parsedOutputAmount)
        }
      }
      else {
        setCalculatedOutputAmount(tryParseAmount('0', currencies[Field.OUTPUT]))
        setOutputAmountOfToken('0')
      }
    }
  }, [maxAmountInput, onUserInput, tokenPrice, currencies, bnbBusdPrice])

  const handleOutputSelect = useCallback(
    (outputCurrency) => {
      onCurrencySelection(Field.OUTPUT, outputCurrency)
      handleTypeOutput('0')
    },

    [onCurrencySelection, handleTypeOutput],
  )

  // Check if approval is necessary, re-check if account changes
  useEffect(() => {
    const onRequiresUSDTApproval = async () => {
      try {
        const response = await USDTContract.allowance(account, PresaleContract.address)
        const currentAllowance = ethersToBigNumber(response)
        if(currentAllowance.gt(0))
          setUSDTApprove(true)
        else
          setUSDTApprove(false)
      } catch (error) {
        setUSDTApprove(false)
      }
    }
  
    const onRequiresBUSDApproval = async () => {
      try {
        const response = await BUSDContract.allowance(account, PresaleContract.address)
        const currentAllowance = ethersToBigNumber(response)
        if(currentAllowance.gt(0))
          setBUSDApprove(true)
        else
          setBUSDApprove(false)
      } catch (error) {
        setBUSDApprove(false)
      }
    }

    const onRequiresPresaleStatus = async () => {
      const _isPresaleOpen = await PresaleContract.isPresaleOpen()
      if(!_isPresaleOpen){
        setIsPresaleOpen(false)
      } else {
        setIsPresaleOpen(true)
      }
    }

    const onRequiresTokenPrice = async () => {
      const _tokenPrice = await PresaleContract.tokenPrice()
      const __tokenPrice = _tokenPrice.toNumber()
      if(__tokenPrice) {
        setTokenPrice(__tokenPrice)
        setInvertTokenPrice(1 / __tokenPrice * 10 ** 12)
      }
    }

    onRequiresPresaleStatus()
    onRequiresTokenPrice()
    if (account) {
      onRequiresUSDTApproval()
      onRequiresBUSDApproval()
    }
  }, [account, USDTContract, BUSDContract, PresaleContract, PresaleContract.address, fastRefresh])

  const buyTokenWithBUSD = useCallback(async () => {
    setRequestedBuy(true)
    const parsedValue = parseUnits(inputAmountOfToken, 18)
    console.log('buyTokenWithBUSD : ' , inputAmountOfToken)
    setBuyState({ attemptingTxn: true, buyErrorMessage: undefined, txHash: undefined })
    const tx = await callWithGasPrice(PresaleContract, 'buyTokenWithBUSD', [parsedValue])
      .then(async (hash) => {
        setBuyState({ attemptingTxn: false, buyErrorMessage: undefined, txHash: hash.hash })
        const receipt = await hash.wait()
        const toast = receipt.status === 1 ? toastSuccess : toastError
        toast(
          t('Transaction receipt'),
          <Flex flexDirection="column">
            <Text>{transactions[receipt.transactionHash]?.summary ?? `Hash: ${receipt.transactionHash.slice(0, 8)}...${receipt.transactionHash.slice(58, 65)}`}</Text>
            {chainId && (
              <Link external href={getBscScanLink(receipt.transactionHash, 'transaction', chainId)}>
                {t('View on BscScan')}
              </Link>
            )}
          </Flex>,
        )
      })
      .catch((error) => {
        setBuyState({
          attemptingTxn: false,
          buyErrorMessage: error.message,
          txHash: undefined,
        })
      })
    setRequestedBuy(false)
  }, [PresaleContract, callWithGasPrice, inputAmountOfToken, chainId, t, toastError, toastSuccess, transactions])

  const buyTokenWithUSDT = useCallback(async () => {
    setRequestedBuy(true)
    const parsedValue = parseUnits(inputAmountOfToken, 18)
    console.log('buyTokenWithUSDT : ' , inputAmountOfToken)
    setBuyState({ attemptingTxn: true, buyErrorMessage: undefined, txHash: undefined })
    const tx = await callWithGasPrice(PresaleContract, 'buyTokenWithUSDT', [parsedValue])
      .then(async (hash) => {
        setBuyState({ attemptingTxn: false, buyErrorMessage: undefined, txHash: hash.hash })
        const receipt = await hash.wait()
        const toast = receipt.status === 1 ? toastSuccess : toastError
        toast(
          t('Transaction receipt'),
          <Flex flexDirection="column">
            <Text>{transactions[receipt.transactionHash]?.summary ?? `Hash: ${receipt.transactionHash.slice(0, 8)}...${receipt.transactionHash.slice(58, 65)}`}</Text>
            {chainId && (
              <Link external href={getBscScanLink(receipt.transactionHash, 'transaction', chainId)}>
                {t('View on BscScan')}
              </Link>
            )}
          </Flex>,
        )
      })
      .catch((error) => {
        setBuyState({
          attemptingTxn: false,
          buyErrorMessage: error.message,
          txHash: undefined,
        })
      })
    setRequestedBuy(false)
  }, [PresaleContract, callWithGasPrice, inputAmountOfToken, chainId, t, toastError, toastSuccess, transactions])

  const buyTokenWithBNB = useCallback(async () => {
    setRequestedBuy(true)
    const parsedValue = parseUnits(inputAmountOfToken, 18)
    console.log('buyTokenWithBNB : ' , inputAmountOfToken)
    setBuyState({ attemptingTxn: true, buyErrorMessage: undefined, txHash: undefined })
    const tx = await callWithGasPrice(PresaleContract, 'buyTokenWithBNB', undefined, { value: parsedValue })
      .then(async (hash) => {
        setBuyState({ attemptingTxn: false, buyErrorMessage: undefined, txHash: hash.hash })
        const receipt = await hash.wait()
        const toast = receipt.status === 1 ? toastSuccess : toastError
        toast(
          t('Transaction receipt'),
          <Flex flexDirection="column">
            <Text>{transactions[receipt.transactionHash]?.summary ?? `Hash: ${receipt.transactionHash.slice(0, 8)}...${receipt.transactionHash.slice(58, 65)}`}</Text>
            {chainId && (
              <Link external href={getBscScanLink(receipt.transactionHash, 'transaction', chainId)}>
                {t('View on BscScan')}
              </Link>
            )}
          </Flex>,
        )
      })
      .catch((error) => {
        setBuyState({
          attemptingTxn: false,
          buyErrorMessage: error.message,
          txHash: undefined,
        })
      })
    setRequestedBuy(false)
  }, [PresaleContract, callWithGasPrice, inputAmountOfToken, chainId, t, toastError, toastSuccess, transactions])

  const handleConfirmDismiss = useCallback(() => {
    setBuyState({ attemptingTxn, buyErrorMessage, txHash })
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.INPUT, '')
    }
  }, [attemptingTxn, onUserInput, buyErrorMessage, txHash])

  const [onPresentBUSDConfirmModal] = useModal(
    <ConfirmBuyModal
      tokenPrice={tokenPrice}
      invertTokenPrice={invertTokenPrice}
      inputCurrency={currencies[Field.INPUT]}
      inputCurrencyAmount={inputAmountOfToken}
      outputCurrency={currencies[Field.OUTPUT]}
      outputCurrencyAmount={outputAmountOfToken}
      onConfirm={buyTokenWithBUSD}
      customOnDismiss={handleConfirmDismiss}
      buyErrorMessage={buyErrorMessage}
      attemptingTxn={attemptingTxn}
      txHash={txHash}
    />,
    true,
    true,
    'confirmBuyWithBUSDModal',
  )

  const [onPresentUSDTConfirmModal] = useModal(
    <ConfirmBuyModal
      tokenPrice={tokenPrice}
      invertTokenPrice={invertTokenPrice}
      inputCurrency={currencies[Field.INPUT]}
      inputCurrencyAmount={inputAmountOfToken}
      outputCurrency={currencies[Field.OUTPUT]}
      outputCurrencyAmount={outputAmountOfToken}
      onConfirm={buyTokenWithUSDT}
      customOnDismiss={handleConfirmDismiss}
      buyErrorMessage={buyErrorMessage}
      attemptingTxn={attemptingTxn}
      txHash={txHash}
    />,
    true,
    true,
    'confirmBuyWithUSDTModal',
  )

  const [onPresentBNBConfirmModal] = useModal(
    <ConfirmBuyModal
      tokenPrice={tokenPrice}
      invertTokenPrice={invertTokenPrice}
      inputCurrency={currencies[Field.INPUT]}
      inputCurrencyAmount={inputAmountOfToken}
      outputCurrency={currencies[Field.OUTPUT]}
      outputCurrencyAmount={outputAmountOfToken}
      onConfirm={buyTokenWithBNB}
      customOnDismiss={handleConfirmDismiss}
      buyErrorMessage={buyErrorMessage}
      attemptingTxn={attemptingTxn}
      txHash={txHash}
    />,
    true,
    true,
    'confirmBuyWithBNBModal',
  )

  return (
    <Page>
      <Flex width="100%" justifyContent="center" position="relative">
        <Flex flexDirection="column">
          <StyledSwapContainer mt='30px' justifyContent="center">
            <StyledInputCurrencyWrapper>
              <AppBody>
                <CurrencyInputHeader
                  title={t('Buy MTW')}
                />
                <Wrapper id="swap-page">
                  <AutoColumn gap="md">
                    <CurrencyInputPanelForPresale
                      label={
                        independentField === Field.OUTPUT ? t('From (estimated)') : t('From')
                      }
                      value={formattedAmounts[Field.INPUT]}
                      showMaxButton={!atMaxAmountInput}
                      currency={currencies[Field.INPUT]}
                      onUserInput={handleTypeInput}
                      onMax={handleMaxInput}
                      onCurrencySelect={handleInputSelect}
                      otherCurrency={currencies[Field.OUTPUT]}
                      id="swap-currency-input"
                      isPresaleTokenList={false}
                    />

                    <AutoColumn justify="space-between">
                      <AutoRow justify='center' style={{ padding: '0 1rem' }}>
                        <IconButton variant="light" scale="sm">
                          <ArrowDownIcon
                            width="16px"
                            color={currencies[Field.INPUT] && currencies[Field.OUTPUT] ? 'primary' : 'text'}
                          />
                        </IconButton>
                      </AutoRow>
                    </AutoColumn>
                    <CurrencyInputPanelForPresale
                      value={formattedAmounts[Field.OUTPUT]}
                      onUserInput={handleTypeOutput}
                      label={independentField === Field.INPUT ? t('To (estimated)') : t('To')}
                      showMaxButton={false}
                      currency={currencies[Field.OUTPUT]}
                      onCurrencySelect={handleOutputSelect}
                      otherCurrency={currencies[Field.INPUT]}
                      id="swap-currency-output"
                      isPresaleTokenList={!false}
                    />
                  </AutoColumn>
                  <AutoColumn gap="8px" style={{ padding: '0 16px' }}>
                    <RowBetween align="center">
                      <Label>{t('Price')}</Label>
                      <TradePrice
                        tokenPrice={tokenPrice}
                        invertTokenPrice={invertTokenPrice}
                        inputCurrency={currencies[Field.INPUT]}
                        outputCurrency={currencies[Field.OUTPUT]}
                        showInverted={showInverted}
                        setShowInverted={setShowInverted}
                      />
                    </RowBetween>
                  </AutoColumn>
                  <Box mt="1rem">
                    {!account ? (
                      <ConnectWalletButton width="100%" />
                    ) : !isPresaleOpen? (
                      <GreyCard style={{ textAlign: 'center' }}>
                        <Text color="textSubtle" mb="4px">
                          {t('Presale is closed')}
                        </Text>
                      </GreyCard>
                    ) : (!currencies[Field.INPUT] || !currencies[Field.OUTPUT]) ? (
                      <GreyCard style={{ textAlign: 'center' }}>
                        <Text color="textSubtle" mb="4px">
                          {t('Please select currency')}
                        </Text>
                      </GreyCard>
                    ) : (currencies[Field.INPUT].symbol === 'USDT' && currencies[Field.OUTPUT].symbol === 'MTW' && !USDTisApproved) ? (
                      <Button mt="8px" width="100%" disabled={requestedApproval} onClick={handleUSDTApprove}>
                        {requestedApproval ? (
                          <AutoRow gap="6px" justify="center">
                            {t('Enabling')} <CircleLoader stroke="white" />
                          </AutoRow>
                        ) : (
                          t('Enable %asset%', { asset: currencies[Field.INPUT]?.symbol ?? '' })
                        )}
                      </Button>
                    ) : (currencies[Field.INPUT].symbol === 'USDT' && currencies[Field.OUTPUT].symbol === 'MTW' && USDTisApproved) ? (
                      <Button
                        mt="8px"
                        width="100%"
                        disabled={requestedBuy}
                        onClick={() => {
                          setBuyState({
                            attemptingTxn: false,
                            buyErrorMessage: undefined,
                            txHash: undefined,
                            })
                          onPresentUSDTConfirmModal()
                        }}
                      >
                        {requestedBuy ? (
                          <AutoRow gap="6px" justify="center">
                            {t('Buying')} <CircleLoader stroke="white" />
                          </AutoRow>
                        ) : (
                          t('Buy')
                        )}
                      </Button>
                    ) : (currencies[Field.INPUT].symbol === 'BUSD' && currencies[Field.OUTPUT].symbol === 'MTW' && !BUSDisApproved) ? (
                      <Button mt="8px" width="100%" disabled={requestedApproval} onClick={handleBUSDApprove}>
                        {requestedApproval ? (
                          <AutoRow gap="6px" justify="center">
                            {t('Enabling')} <CircleLoader stroke="white" />
                          </AutoRow>
                        ) : (
                          t('Enable %asset%', { asset: currencies[Field.INPUT]?.symbol ?? '' })
                        )}
                      </Button>
                    ) : (currencies[Field.INPUT].symbol === 'BUSD' && currencies[Field.OUTPUT].symbol === 'MTW' && BUSDisApproved) ? (
                      <Button
                        mt="8px"
                        width="100%"
                        disabled={requestedBuy}
                        onClick={() => {
                          setBuyState({
                            attemptingTxn: false,
                            buyErrorMessage: undefined,
                            txHash: undefined,
                            })
                          onPresentBUSDConfirmModal()
                        }}
                      >
                        {requestedBuy ? (
                          <AutoRow gap="6px" justify="center">
                            {t('Buying')} <CircleLoader stroke="white" />
                          </AutoRow>
                        ) : (
                          t('Buy')
                        )}
                      </Button>
                    ) : (currencies[Field.INPUT].symbol === 'BNB' && currencies[Field.OUTPUT].symbol === 'MTW') ? (
                      <Button
                        mt="8px"
                        width="100%"
                        disabled={requestedBuy}
                        onClick={() => {
                          setBuyState({
                            attemptingTxn: false,
                            buyErrorMessage: undefined,
                            txHash: undefined,
                            })
                          onPresentBNBConfirmModal()
                        }}
                      >
                        {requestedBuy ? (
                          <AutoRow gap="6px" justify="center">
                            {t('Buying')} <CircleLoader stroke="white" />
                          </AutoRow>
                        ) : (
                          t('Buy')
                        )}
                      </Button>
                    ) : (
                      <GreyCard style={{ textAlign: 'center' }}>
                        <Text color="textSubtle" mb="4px">
                          {t('Please select correct currency')}
                        </Text>
                      </GreyCard>
                    )}
                  </Box>
                </Wrapper>
              </AppBody>
            </StyledInputCurrencyWrapper>
          </StyledSwapContainer>
          {/* <StyledText mt='50px' width='350px'>
            20,000 tokens were sold in 1$. 20,000 will be sold in $2.
            And rest of the 200,000 will be listed $5 in Metaworthswap and pancakeswap
          </StyledText> */}
        </Flex>
      </Flex>
    </Page>
  )
}
