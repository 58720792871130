import React from 'react'
import { Currency, Price } from '@pancakeswap/sdk'
import { Text, AutoRenewIcon } from '@pancakeswap/uikit'
import { useBNBBusdPrice } from 'hooks/useBUSDPrice'
import { StyledBalanceMaxMini } from './styleds'


interface TradePriceProps {
  tokenPrice: number
  invertTokenPrice: number
  inputCurrency: Currency
  outputCurrency: Currency
  showInverted: boolean
  setShowInverted: (showInverted: boolean) => void
}

export default function TradePrice({ tokenPrice, invertTokenPrice, inputCurrency, outputCurrency, showInverted, setShowInverted }: TradePriceProps) {
  const _bnbBusdPrice = useBNBBusdPrice()?.toSignificant(6)
  const bnbBusdPrice = (+_bnbBusdPrice)*10**6
  const formattedPrice = showInverted ? (inputCurrency?.symbol === 'BNB'? (tokenPrice/bnbBusdPrice) : (tokenPrice/10**6)) : (inputCurrency?.symbol === 'BNB'? (invertTokenPrice*bnbBusdPrice/10**12) : (invertTokenPrice/10**6))
  
  const show = Boolean(inputCurrency && outputCurrency)
  const label = showInverted
    ? `${inputCurrency?.symbol} per ${outputCurrency?.symbol}`
    : `${outputCurrency?.symbol} per ${inputCurrency?.symbol}`

  return (
    <Text style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
      {show ? (
        <>
          {formattedPrice?.toFixed(2) ?? '-'} {label}
          <StyledBalanceMaxMini onClick={() => setShowInverted(!showInverted)}>
            <AutoRenewIcon width="14px" />
          </StyledBalanceMaxMini>
        </>
      ) : (
        '-'
      )}
    </Text>
  )
}
