import { useCallback } from 'react'
import { ethers, Contract } from 'ethers'
import { usePresaleContract, useUSDTContract } from 'hooks/useContract'
import { useCallWithGasPrice } from 'hooks/useCallWithGasPrice'

const useApproveUSDT = () => {
  const PresaleContract = usePresaleContract()
  const USDTContract = useUSDTContract()
  const { callWithGasPrice } = useCallWithGasPrice()
  const handleApprove = useCallback(async () => {
    const tx = await callWithGasPrice(USDTContract, 'approve', [PresaleContract.address, ethers.constants.MaxUint256])
    const receipt = await tx.wait()
    return receipt.status
  }, [USDTContract, PresaleContract, callWithGasPrice])

  return { onApproveUSDT: handleApprove }
}

export default useApproveUSDT
