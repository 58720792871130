import React, { useCallback, useMemo, useRef, useState } from 'react'
import { Currency, Token } from '@pancakeswap/sdk'
import { Box } from '@pancakeswap/uikit'
import { useTranslation } from 'contexts/Localization'
import { FixedSizeList } from 'react-window'
import { useAudioModeManager } from 'state/user/hooks'
import useDebounce from 'hooks/useDebounce'
import { useTokensForPresale, usePresaleTokens, useFoundOnInactiveList } from '../../hooks/Tokens'
import CurrencyList from './CurrencyList'
import { filterTokens, useSortedTokensByQuery } from './filtering'
import useTokenComparator from './sorting'

interface CurrencySearchProps {
  selectedCurrency?: Currency | null
  onCurrencySelect: (currency: Currency) => void
  otherSelectedCurrency?: Currency | null
  showCommonBases?: boolean
  showImportView: () => void
  setImportToken: (token: Token) => void
  isPresaleTokenList?: boolean
}

const swapSound = new Audio('swap.mp3')

function CurrencySearchForPresale({
  selectedCurrency,
  onCurrencySelect,
  otherSelectedCurrency,
  showCommonBases,
  showImportView,
  setImportToken,
  isPresaleTokenList,
}: CurrencySearchProps) {
  const { t } = useTranslation()

  // refs for fixed size lists
  const fixedList = useRef<FixedSizeList>()

  const [searchQuery, setSearchQuery] = useState<string>('')
  const debouncedQuery = useDebounce(searchQuery, 200)

  const [invertSearchOrder] = useState<boolean>(false)

  const TokensForPresale = useTokensForPresale()
  const PresaleTokens = usePresaleTokens()

  const [audioPlay] = useAudioModeManager()

  const showETH: boolean = useMemo(() => {
    const s = debouncedQuery.toLowerCase().trim()
    return s === '' || s === 'b' || s === 'bn' || s === 'bnb'
  }, [debouncedQuery])

  const tokenComparator = useTokenComparator(invertSearchOrder)

  const filteredTokens: Token[] = useMemo(() => {
    return filterTokens(Object.values(TokensForPresale), debouncedQuery)
  }, [TokensForPresale, debouncedQuery])

  const filteredPresaleTokens: Token[] = useMemo(() => {
    return filterTokens(Object.values(PresaleTokens), debouncedQuery)
  }, [PresaleTokens, debouncedQuery])

  const sortedTokens: Token[] = useMemo(() => {
    return filteredTokens.sort(tokenComparator)
  }, [filteredTokens, tokenComparator])

  const sortedPresaleTokens: Token[] = useMemo(() => {
    return filteredPresaleTokens.sort(tokenComparator)
  }, [filteredPresaleTokens, tokenComparator])

  const filteredSortedTokens = useSortedTokensByQuery(sortedTokens, debouncedQuery)
  const filteredSortedPresaleTokens = useSortedTokensByQuery(sortedPresaleTokens, debouncedQuery)

  const handleCurrencySelect = useCallback(
    (currency: Currency) => {
      onCurrencySelect(currency)
      if (audioPlay) {
        swapSound.play()
      }
    },
    [audioPlay, onCurrencySelect],
  )

  // if no results on main list, show option to expand into inactive
  const inactiveTokens = useFoundOnInactiveList(debouncedQuery)
  const filteredInactiveTokens: Token[] = useSortedTokensByQuery(inactiveTokens, debouncedQuery)

  return (
    <>
      <div>
        <Box margin="24px -24px">
          {isPresaleTokenList ? (<CurrencyList
            height={390}
            showETH={false}
            currencies={
              filteredInactiveTokens ? filteredSortedPresaleTokens.concat(filteredInactiveTokens) : filteredSortedPresaleTokens
            }
            breakIndex={inactiveTokens && filteredSortedPresaleTokens ? filteredSortedPresaleTokens.length : undefined}
            onCurrencySelect={handleCurrencySelect}
            otherCurrency={otherSelectedCurrency}
            selectedCurrency={selectedCurrency}
            fixedListRef={fixedList}
            showImportView={showImportView}
            setImportToken={setImportToken}
          />) : (<CurrencyList
            height={390}
            showETH={!false}
            currencies={
              filteredInactiveTokens ? filteredSortedTokens.concat(filteredInactiveTokens) : filteredSortedTokens
            }
            breakIndex={inactiveTokens && filteredSortedTokens ? filteredSortedTokens.length : undefined}
            onCurrencySelect={handleCurrencySelect}
            otherCurrency={otherSelectedCurrency}
            selectedCurrency={selectedCurrency}
            fixedListRef={fixedList}
            showImportView={showImportView}
            setImportToken={setImportToken}
          />)}
        </Box>
      </div>
    </>
  )
}

export default CurrencySearchForPresale
