import React, { useMemo } from 'react'
import { Currency, Trade, TradeType } from '@pancakeswap/sdk'
import { Button, Text, ErrorIcon, ArrowDownIcon } from '@pancakeswap/uikit'
import { useTranslation } from 'contexts/Localization'
import { AutoColumn } from 'components/Layout/Column'
import { CurrencyLogo } from 'components/Logo'
import { RowBetween, RowFixed } from 'components/Layout/Row'
import truncateHash from 'utils/truncateHash'
import { TruncatedText } from './styleds'

export default function BuyModalHeader({
  inputCurrency,
  inputCurrencyAmount,
  outputCurrency,
  outputCurrencyAmount,
}: {
  inputCurrency: Currency
  inputCurrencyAmount: string
  outputCurrency: Currency
  outputCurrencyAmount: string
}) {
  const { t } = useTranslation()

  const amount = outputCurrencyAmount
  const symbol = outputCurrency.symbol

  const tradeInfoText = t('Output is estimated. You will receive %amount% %symbol% or the transaction will revert.', {
          amount,
          symbol,
        })

  const [estimatedText, transactionRevertText] = tradeInfoText.split(`${amount} ${symbol}`)

  return (
    <AutoColumn gap="md">
      <RowBetween align="flex-end">
        <RowFixed gap="0px">
          <CurrencyLogo currency={inputCurrency} size="24px" style={{ marginRight: '12px' }} />
          <TruncatedText fontSize="24px" color='text'>
            {inputCurrencyAmount}
          </TruncatedText>
        </RowFixed>
        <RowFixed gap="0px">
          <Text fontSize="24px" ml="10px">
            {inputCurrency.symbol}
          </Text>
        </RowFixed>
      </RowBetween>
      <RowFixed>
        <ArrowDownIcon width="16px" ml="4px" />
      </RowFixed>
      <RowBetween align="flex-end">
        <RowFixed gap="0px">
          <CurrencyLogo currency={outputCurrency} size="24px" style={{ marginRight: '12px' }} />
          <TruncatedText fontSize="24px" color='text'>
            {outputCurrencyAmount}
          </TruncatedText>
        </RowFixed>
        <RowFixed gap="0px">
          <Text fontSize="24px" ml="10px">
            {outputCurrency.symbol}
          </Text>
        </RowFixed>
      </RowBetween>
      <AutoColumn justify="flex-start" gap="sm" style={{ padding: '24px 0 0 0px' }}>
        <Text small color="textSubtle" textAlign="left" style={{ width: '100%' }}>
          {estimatedText}
          <b>
            {amount}
          </b>
          {transactionRevertText}
        </Text>
      </AutoColumn>
    </AutoColumn>
  )
}
