import { ContextApi } from 'contexts/Localization/types'
import { PageMeta } from './types'

export const DEFAULT_META: PageMeta = {
  title: 'MetaWorth',
  description:
    'The most popular AMM on BSC by user count! Earn MTW through yield farming or win it in the Lottery, then stake it in MTWCHIP Pools to earn more tokens! Initial Farm Offerings, NFTs, and more, on a platform you can trust.',
  image: 'https://app.metaworth.exchange/images/hero.png',
}

export const getCustomMeta = (path: string, t: ContextApi['t']): PageMeta => {
  let basePath
  if (path.startsWith('/swap')) {
    basePath = '/swap'
  } else if (path.startsWith('/add')) {
    basePath = '/add'
  } else if (path.startsWith('/remove')) {
    basePath = '/remove'
  } else if (path.startsWith('/teams')) {
    basePath = '/teams'
  } else if (path.startsWith('/voting/proposal') && path !== '/voting/proposal/create') {
    basePath = '/voting/proposal'
  } else if (path.startsWith('/nfts/collections')) {
    basePath = '/nfts/collections'
  } else if (path.startsWith('/nfts/profile')) {
    basePath = '/nfts/profile'
  } else if (path.startsWith('/pancake-squad')) {
    basePath = '/pancake-squad'
  } else if (path.startsWith('/presale')) {
    basePath = '/presale'
  } else {
    basePath = path
  }

  switch (basePath) {
    case '/':
      return {
        title: `${t('Presale')} | ${t('MetaWorth')}`,
      }
    case '/presale':
      return {
        title: `${t('Presale')} | ${t('MetaWorth')}`,
      }
    case '/swap':
      return {
        title: `${t('Exchange')} | ${t('MetaWorth')}`,
      }
    case '/add':
      return {
        title: `${t('Add Liquidity')} | ${t('MetaWorth')}`,
      }
    case '/remove':
      return {
        title: `${t('Remove Liquidity')} | ${t('MetaWorth')}`,
      }
    case '/liquidity':
      return {
        title: `${t('Liquidity')} | ${t('MetaWorth')}`,
      }
    case '/find':
      return {
        title: `${t('Import Pool')} | ${t('MetaWorth')}`,
      }
    case '/competition':
      return {
        title: `${t('Trading Battle')} | ${t('MetaWorth')}`,
      }
    case '/prediction':
      return {
        title: `${t('Prediction')} | ${t('MetaWorth')}`,
      }
    case '/prediction/leaderboard':
      return {
        title: `${t('Leaderboard')} | ${t('MetaWorth')}`,
      }
    case '/farms':
      return {
        title: `${t('Farms')} | ${t('MetaWorth')}`,
      }
    case '/farms/auction':
      return {
        title: `${t('Farm Auctions')} | ${t('MetaWorth')}`,
      }
    case '/pools':
      return {
        title: `${t('Pools')} | ${t('MetaWorth')}`,
      }
    case '/lottery':
      return {
        title: `${t('Lottery')} | ${t('MetaWorth')}`,
      }
    case '/ifo':
      return {
        title: `${t('Initial Farm Offering')} | ${t('MetaWorth')}`,
      }
    case '/teams':
      return {
        title: `${t('Leaderboard')} | ${t('MetaWorth')}`,
      }
    case '/voting':
      return {
        title: `${t('Voting')} | ${t('MetaWorth')}`,
      }
    case '/voting/proposal':
      return {
        title: `${t('Proposals')} | ${t('MetaWorth')}`,
      }
    case '/voting/proposal/create':
      return {
        title: `${t('Make a Proposal')} | ${t('MetaWorth')}`,
      }
    case '/info':
      return {
        title: `${t('Overview')} | ${t('MetaWorth Info & Analytics')}`,
        description: 'View statistics for MetaWorth exchanges.',
      }
    case '/info/pools':
      return {
        title: `${t('Pools')} | ${t('MetaWorth Info & Analytics')}`,
        description: 'View statistics for MetaWorth exchanges.',
      }
    case '/info/tokens':
      return {
        title: `${t('Tokens')} | ${t('MetaWorth Info & Analytics')}`,
        description: 'View statistics for MetaWorth exchanges.',
      }
    case '/nfts':
      return {
        title: `${t('Overview')} | ${t('MetaWorth')}`,
      }
    case '/nfts/collections':
      return {
        title: `${t('Collections')} | ${t('MetaWorth')}`,
      }
    case '/nfts/profile':
      return {
        title: `${t('Your Profile')} | ${t('MetaWorth')}`,
      }
    case '/pancake-squad':
      return {
        title: `${t('Pancake Squad')} | ${t('MetaWorth')}`,
      }
    default:
      return null
  }
}
