import React, { useCallback, useMemo } from 'react'
import { Currency, currencyEquals, Trade } from '@pancakeswap/sdk'
import { InjectedModalProps } from '@pancakeswap/uikit'
import { useTranslation } from 'contexts/Localization'
import TransactionConfirmationModal, {
  ConfirmationModalContent,
  TransactionErrorContent,
} from 'components/TransactionConfirmationModal'
import BuyModalFooter from './BuyModalFooter'
import BuyModalHeader from './BuyModalHeader'

interface ConfirmBuyModalProps {
  tokenPrice: number
  invertTokenPrice: number
  inputCurrency: Currency
  inputCurrencyAmount: string
  outputCurrency: Currency
  outputCurrencyAmount: string
  attemptingTxn: boolean
  txHash?: string
  onConfirm: () => void
  buyErrorMessage?: string
  customOnDismiss?: () => void
}

const ConfirmBuyModal: React.FC<InjectedModalProps & ConfirmBuyModalProps> = ({
  tokenPrice,
  invertTokenPrice,
  inputCurrency,
  inputCurrencyAmount,
  outputCurrency,
  outputCurrencyAmount,
  onConfirm,
  onDismiss,
  customOnDismiss,
  buyErrorMessage,
  attemptingTxn,
  txHash,
}) => {

  const { t } = useTranslation()

  const modalHeader = useCallback(() => {
    return <BuyModalHeader
        inputCurrency={inputCurrency}
        inputCurrencyAmount={inputCurrencyAmount}
        outputCurrency={outputCurrency}
        outputCurrencyAmount={outputCurrencyAmount}
      />
  }, [inputCurrency, inputCurrencyAmount, outputCurrency, outputCurrencyAmount])

  const modalBottom = useCallback(() => {
    return <BuyModalFooter
        tokenPrice={tokenPrice}
        invertTokenPrice={invertTokenPrice}
        inputCurrency={inputCurrency}
        outputCurrency={outputCurrency}
        onConfirm={onConfirm}
        buyErrorMessage={buyErrorMessage}
      />
  }, [tokenPrice, invertTokenPrice, inputCurrency, outputCurrency, onConfirm, buyErrorMessage])

  // text to show while loading
  const pendingText = t('Sending %amountA% %symbolA% for %amountB% %symbolB%', {
    amountA: inputCurrencyAmount,
    symbolA: inputCurrency.symbol,
    amountB: outputCurrencyAmount,
    symbolB: outputCurrency.symbol,
  })

  const confirmationContent = useCallback(
    () =>
      buyErrorMessage ? (
        <TransactionErrorContent onDismiss={onDismiss} message={buyErrorMessage} />
      ) : (
        <ConfirmationModalContent topContent={modalHeader} bottomContent={modalBottom} />
      ),
    [onDismiss, modalBottom, modalHeader, buyErrorMessage],
  )

  return (
    <TransactionConfirmationModal
      title={t('Confirm Buy')}
      onDismiss={onDismiss}
      customOnDismiss={customOnDismiss}
      attemptingTxn={attemptingTxn}
      hash={txHash}
      content={confirmationContent}
      pendingText={pendingText}
      currencyToAdd={outputCurrency}
    />
  )
}

export default ConfirmBuyModal
